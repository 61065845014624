<template>
    <div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title">
                    <h3 class="card-label">
                        {{ $t('financial_years.financial_years')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
<!--                    <router-link to="/accounting/financial-years" class="btn btn-light font-weight-bolder">-->
<!--                        <v-icon>mdi-chevron-left</v-icon>-->
<!--                        {{ $t('back') }}-->
<!--                    </router-link>-->
                    <!--end::Button-->
                </div>
            </div>

            <div class="card-body">
                    <b-tabs content-class="mt-3">
                        <b-tab :title="$t('activity_log.basic_information')" active>
                            <br>
                            <div>
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th>{{$t('financial_years.start_date')}}</th>
                                            <td>{{data.start_date}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('financial_years.end_date')}}</th>
                                            <td>{{data.end_date}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('financial_years.description')}}</th>
                                            <td>{{data.description}}</td>
                                        </tr>
                                        <tr v-if="data.created_at">
                                            <th>{{$t('created_at')}}</th>
                                            <td>{{data.created_at}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-tab>
                        <b-tab :title="$t('activity_log.activity_log')">
                            <br>
                            <activity-log :inner-key="key" :id="id"></activity-log>
                        </b-tab>
                    </b-tabs>
                </div>
        </div>
        <!--end::customer-->
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ActivityLog from "@/view/content/widgets/details/ActivityLog";

    export default {
        name: "view-financial-years",
        components:{'activity-log': ActivityLog},
        data() {
            return {
                mainRoute: 'accounting/financial-years',
                id: this.$route.params.id? this.$route.params.id : null,
                key: 'FinancialYear',
                data: {},
            };
        },

        methods: {
            async getData() {
                ApiService.get(`${this.mainRoute}/${this.id}`).then((response) => {
                    this.data = response.data.data;
                });
            },
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.financial_years"), route:'/accounting/financial-years'}, {title: this.$t('view')}]);
            if (this.id) {
                this.getData();
            }

        },
    };
</script>